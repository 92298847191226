<script>
  // # # # # # # # # # # # # #
  //
  //  Case Study List
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import Fuse from "fuse.js"
  import { renderBlockText } from "../sanity.js"

  // COMPONENTS
  import ParticipantsList from "./ParticipantsList.svelte"

  // *** PROPS
  export let caseStudies = []
  export let related = false

  // *** STORES
  import { globalSettings } from "../stores.js"

  // *** VARIABLES
  let filterTerm = ""
  let filteredCaseStudies = []
  let orderedCaseStudies = {}
  let fuseList = {}
  let sortOrder = "title"

  const fuseOptions = {
    threshold: 0.2,
    keys: ["title", "category", "participants.name"],
  }

  const titleSort = (a, b) => {
    const textA = a.title ? a.title.toUpperCase() : "Undefined"
    const textB = b.title ? b.title.toUpperCase() : "Undefined"
    return textA < textB ? -1 : textA > textB ? 1 : 0
  }

  const seminarSort = (a, b) => {
    const textA = a.category ? a.category.toUpperCase() : "Undefined"
    const textB = b.category ? b.category.toUpperCase() : "Undefined"
    return textA < textB ? -1 : textA > textB ? 1 : 0
  }

  orderedCaseStudies["title"] = [...caseStudies].sort(titleSort)
  orderedCaseStudies["seminar"] = [...caseStudies].sort(seminarSort)

  fuseList["title"] = new Fuse(orderedCaseStudies["title"], fuseOptions)
  fuseList["seminar"] = new Fuse(orderedCaseStudies["seminar"], fuseOptions)

  // FILTER
  $: {
    if (filterTerm) {
      filteredCaseStudies = fuseList[sortOrder]
        .search(filterTerm)
        .map(hit => hit.item)
    } else {
      filteredCaseStudies = orderedCaseStudies[sortOrder]
    }
  }
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.case-study-container {
  height: 100%;
  color: black;
  font-family: "hersheyduplex", "IBM Plex Mono", monospace;
  font-size: 16px;
  background: #f0f0f0; }
  .case-study-container .case-study-item {
    padding: 0px 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background: #f0f0f0;
    color: black;
    display: inline-block;
    text-decoration: none;
    user-select: none;
    transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
    .case-study-container .case-study-item .inner {
      width: 100%; }
      .case-study-container .case-study-item .inner .color-icon {
        display: none;
        height: 10px;
        width: 10px;
        margin-right: 18px;
        float: left;
        margin-top: 4px;
        background: #ed3656; }
        .case-study-container .case-study-item .inner .color-icon.communication {
          background: #1cc227; }
        .case-study-container .case-study-item .inner .color-icon.consensus-building {
          background: #a2dafd; }
        .case-study-container .case-study-item .inner .color-icon.sensing {
          background: #fff538; }
        .case-study-container .case-study-item .inner .color-icon.archiving {
          background: #f8c2e8; }
      .case-study-container .case-study-item .inner .mid-section {
        width: 60%;
        float: left; }
        .case-study-container .case-study-item .inner .mid-section .title {
          font-family: "hersheyduplex", "IBM Plex Sans", sans;
          font-weight: 500; }
        .case-study-container .case-study-item .inner .mid-section .participants {
          font-family: "hersheyduplex", "IBM Plex Mono", monospace;
          pointer-events: none;
          color: #999999;
          font-size: 14px; }
      .case-study-container .case-study-item .inner .date {
        white-space: nowrap;
        color: #999999;
        float: right; }
    .case-study-container .case-study-item:hover {
      background: #c5c5c5; }
    .case-study-container .case-study-item.header {
      height: 45px;
      border-bottom: 1px solid #c5c5c5;
      background-color: white; }
      .case-study-container .case-study-item.header:hover {
        background: white !important; }
      .case-study-container .case-study-item.header.related {
        background-color: unset; }
        .case-study-container .case-study-item.header.related:hover {
          background: unset !important; }
      .case-study-container .case-study-item.header .archive-link {
        color: #999999;
        text-decoration: underline; }
      .case-study-container .case-study-item.header:hover {
        background: unset; }
    .case-study-container .case-study-item.related {
      padding-left: 0;
      padding-right: 0; }
      .case-study-container .case-study-item.related.header {
        height: 30px;
        padding-top: 0; }
  .case-study-container .description {
    padding: 12px; }
  .case-study-container .toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 12px;
    font-size: 14px;
    border-bottom: 1px dotted #c5c5c5;
    margin-bottom: 12px; }
    .case-study-container .toolbar .sort {
      height: 100%;
      display: flex;
      align-items: center; }
      .case-study-container .toolbar .sort select {
        margin-left: 6px;
        font-size: 14px;
        color: black;
        font-family: "hersheyduplex", "IBM Plex Mono", monospace;
        border: none;
        background: #f0f0f0; }
    .case-study-container .toolbar .filter {
      height: 100%;
      display: flex;
      align-items: center; }
      .case-study-container .toolbar .filter svg {
        margin-right: 6px; }
        .case-study-container .toolbar .filter svg path {
          color: #c5c5c5; }
      .case-study-container .toolbar .filter input {
        width: 8ch;
        border: none;
        outline: none;
        font-size: 14px;
        color: black;
        font-family: "hersheyduplex", "IBM Plex Mono", monospace;
        background: #f0f0f0; }
        .case-study-container .toolbar .filter input input[type="text"] {
          -webkit-appearance: none; }
        .case-study-container .toolbar .filter input select {
          -webkit-appearance: none; }
        .case-study-container .toolbar .filter input:focus {
          border-bottom: 1px solid black;
          width: 16ch; }
</style>

<div class="case-study-container">
  <!-- HEADER -->
  <div class="case-study-item header" class:related>
    <div class="inner">
      <div class="row">
        <div>{related ? 'Connected Projects' : 'Projects'}</div>
      </div>
    </div>
  </div>

  {#if !related}
    <!-- TEXT -->
    {#if Array.isArray(get($globalSettings, 'caseStudyOverview.content', false))}
      <div class="description">
        {@html renderBlockText($globalSettings.caseStudyOverview.content)}
      </div>
    {/if}
    <!-- TOOLBAR -->
    <div class="toolbar">
      <div class="sort">
        <div>Sort by:</div>
        <select name="sortOrder" bind:value={sortOrder}>
          <option value="title" selected>Title</option>
          <option value="seminar">Type</option>
        </select>
      </div>
      <div class="filter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"><path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg><input
          type="[text]"
          placeholder="Search"
          bind:value={filterTerm} />
      </div>
    </div>
  {/if}
  <!-- CASE STUDIES -->
  {#each related ? caseStudies : filteredCaseStudies as caseStudy, index (caseStudy._id)}
    <a
      class="case-study-item"
      class:related
      href={'/projects/' + get(caseStudy, 'slug.current', '')}>
      <div class="inner">
        <div class="color-icon {caseStudy.category}" />
        <div class="mid-section">
          <div class="title">{caseStudy.title}</div>
          <div class="participants">
            {#if get(caseStudy, 'participants', false) && Array.isArray(caseStudy.participants)}
              <ParticipantsList participants={caseStudy.participants} />
            {/if}
          </div>
        </div>
        <div class="date">
          {#if caseStudy.category}{caseStudy.category}{/if}
        </div>
      </div>
    </a>
  {/each}
</div>
