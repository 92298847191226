<script>
  // # # # # # # # # # # # # #
  //
  //  Minimap
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"

  // *** PROPS
  export let miniImage = false
  export let player = false
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.map-container {
  height: 200px;
  width: 200px;
  position: relative;
  transform: scale(1.55);
  user-select: none; }
  .map-container img {
    height: 200px;
    width: 200px; }
  .map-container .map-marker {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100; }
</style>

<div class="map-container">
  {#if miniImage}<img src={miniImage} alt="minimap" />{/if}
  {#if get(player, 'avatar.y', false) && get(player, 'avatar.x', false)}
    <div
      class="map-marker"
      style={'top: ' + Math.round(player.avatar.y / 20 - 5) + 'px; left: ' + Math.round(player.avatar.x / 20 - 5) + 'px;'} />
  {/if}
</div>
