<script>
    // # # # # # # # # # # # # #
    //
    //  RECONNECTION SCREEN
    //
    // # # # # # # # # # # # # #
  
    // *** PROPS
    export let reconnectionAttempts = 0
    export let disconnectionCode = 0
  </script>
  
  <style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.reconnection {
  position: fixed;
  bottom: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; }
  @media (max-width: 800px) {
    .reconnection {
      bottom: 55px;
      left: 0; } }
  .reconnection .box {
    font-family: "hersheyduplex", "IBM Plex Mono", monospace;
    padding: 20px;
    background: white;
    color: black;
    font-size: 16px;
    text-align: center;
    user-select: none; }
    @media (max-width: 800px) {
      .reconnection .box {
        width: calc(100% - 20px); } }
</style>
  
  <div class="reconnection">
    <div class="box">
      <div class="header">You have been disconnected from the gameserver. (Error: {disconnectionCode})</div>
      <div class="header">Please reload the page to attempt to reconnect.</div>
    </div>
  </div>
  