<script>
  // # # # # # # # # # # # # #
  //
  //  Single Event
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import ParticipantsList from "../../lists/ParticipantsList.svelte"
  import CaseStudyList from "../../lists/CaseStudyList.svelte"
  import MetaData from "../../MetaData.svelte"

  // *** GLOBAL
  import { formattedDate } from "../../global.js"

  // *** PROPS
  export let event = {}
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.event-single .go-back {
  padding-top: 6px;
  padding-left: 12px;
  cursor: pointer; }
  .event-single .go-back svg path {
    fill: #999999;
    transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .event-single .go-back:hover svg path {
    fill: #555555; }

.event-single .main-header {
  padding: 18px; }
  .event-single .main-header .title {
    font-family: "hersheyduplex", "IBM Plex Sans", sans;
    font-size: 32px;
    font-weight: bold; }
  .event-single .main-header .participants {
    margin-top: 6px;
    color: #555555;
    font-family: "hersheyduplex", "IBM Plex Mono", monospace;
    font-size: 14px;
    display: inline-block; }

.event-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.event-single .date {
  padding: 18px;
  text-align: center;
  font-weight: 500;
  font-family: "hersheyduplex", "IBM Plex Sans", sans; }

.event-single .image {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px; }
  .event-single .image img {
    width: 100%;
    max-height: 300px;
    object-fit: cover; }

.event-single .text {
  padding: 18px 18px 0 18px;
  font-family: "hersheyduplex", "IBM Plex Sans", sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .event-single .text {
      padding-bottom: 18px; } }

.event-single .connected-case-studies {
  padding: 18px; }
</style>

<!-- METADATA -->
<!-- <MetaData post={event} /> -->

<div class="event-single" in:fade use:links>
  <!-- BACK LINK -->
  <div
    class="go-back"
    on:click={e => {
      window.history.back()
    }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"><path
        d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" /></svg>
  </div>
  <!-- HEADER -->
  <div class="main-header">
    <!-- TITLE -->
    <div class="title">{event.title}</div>

    <!-- PARTICIPANTS -->
    <div class="participants">
      {#if get(event, 'moderators', false) && Array.isArray(event.moderators)}
        <ParticipantsList
          participants={event.moderators}
          isModerators
          messaging={true} />
      {/if}
      {#if get(event, 'participants', false) && Array.isArray(event.participants)}
        <ParticipantsList participants={event.participants} messaging={true} />
      {/if}
    </div>
  </div>
  <div class="divider" />

  <!-- DATE -->
  <div class="date">{formattedDate(event.startDate)}</div>
  <div class="divider" />

  <!-- TEXT -->
  {#if Array.isArray(get(event, 'content.content', false)) && event.content.content.length > 0}
    <div class="text">
      {@html renderBlockText(event.content.content)}
    </div>
    <div class="divider" />
  {/if}

  <!-- CONNECTED CASE STUDIES -->
  <div class="connected-case-studies">
    {#if Array.isArray(get(event, 'connectedCaseStudies')) && event.connectedCaseStudies.length > 0}
      <CaseStudyList caseStudies={event.connectedCaseStudies} related={true} />
    {/if}
  </div>
</div>
