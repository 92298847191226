<script>
  // # # # # # # # # # # # # #
  //
  //  CARD
  //
  // # # # # # # # # # # # # #

  // *** IMPORT
  import { urlFor, renderBlockText } from "../sanity.js"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { links } from "svelte-routing"

  // *** PROPS
  export let card = {}
  export let showWelcomeCard = false;

  // *** VARIABLES
  let currentIndex = 0
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.tutorial-wrap-inner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none; }

.tutorial {
  font-size: 20px;
  font-family: "hersheydashed", sans-serif;
  padding: 24px 24px 72px 24px;
  margin: 0 auto;
  background: #bacfc9;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  max-width: 900px;
  min-height: 60%;
  pointer-events: all;
  position: relative; }
  @media (max-width: 800px) {
    .tutorial {
      font-size: 14px;
      width: calc(100vw - 18px*2);
      height: calc(100% - 18px*2); } }
  .tutorial .tutorial-slide :global(figure) {
    display: block;
    margin: 24px 0; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide :global(figure) {
        margin: 12px 0; } }
  .tutorial .tutorial-slide :global(img) {
    max-width: 50%;
    margin: unset;
    object-fit: cover;
    margin: 0 auto;
    display: block; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide :global(img) {
        max-width: 100%; } }
  .tutorial .tutorial-slide :global(ol) {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide :global(ol) {
        justify-content: center; } }
  .tutorial .tutorial-slide :global(li) {
    white-space: nowrap;
    margin-bottom: 12px; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide :global(li) {
        margin-right: 12px; } }
  .tutorial .tutorial-slide :global(a) {
    text-decoration: none; }
    .tutorial .tutorial-slide :global(a):hover {
      opacity: 0.4; }
  .tutorial .tutorial-slide :global(li::before) {
    content: " ";
    width: 24px;
    height: 24px;
    margin-bottom: -6px;
    margin-right: 12px;
    display: inline-block; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide :global(li::before) {
        margin-right: 6px; } }
  .tutorial .tutorial-slide :global(li:nth-of-type(1)::before) {
    background: #1cc227; }
  .tutorial .tutorial-slide :global(li:nth-of-type(2)::before) {
    background: #fff538; }
  .tutorial .tutorial-slide :global(li:nth-of-type(3)::before) {
    background: #f8c2e8; }
  .tutorial .tutorial-slide :global(li:nth-of-type(4)::before) {
    background: #a2dafd; }
  .tutorial .tutorial-slide {
    font-family: "hersheydashed", sans-serif;
    width: 90%;
    min-height: 30%;
    text-align: left; }
    @media (max-width: 800px) {
      .tutorial .tutorial-slide {
        width: 100%; } }
  .tutorial .navigation {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 24px; }
    .tutorial .navigation .nav-button {
      font-size: 14px;
      padding: 12px 18px;
      background: #f0f0f0;
      font-family: "hersheyduplex", "IBM Plex Mono", monospace;
      transition: background 150ms cubic-bezier(0.23, 1, 0.32, 1);
      border-radius: 20px;
      cursor: pointer;
      user-select: none;
      border: 1px solid black;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.05em; }
      .tutorial .navigation .nav-button:hover {
        background: #bbbbbb; }
      .tutorial .navigation .nav-button.back {
        margin-right: 10px; }
      .tutorial .navigation .nav-button.enter {
        background-color: black;
        color: #f0f0f0; }
      .tutorial .navigation .nav-button.disabled {
        opacity: 0.5;
        pointer-events: none; }
  .tutorial .close {
    margin-bottom: 20px;
    position: absolute;
    top: 2px;
    right: 6px;
    fill: #999999;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 10000; }
    @media (max-width: 800px) {
      .tutorial .close {
        margin-bottom: 0;
        margin-top: 0; }
        .tutorial .close.passive {
          min-height: 100vh; } }
    .tutorial .close:hover {
      fill: #555555; }
  @media (max-width: 800px) {
    .tutorial {
      margin-top: 0;
      margin-bottom: 0; }
      .tutorial.passive {
        min-height: 100vh; } }
</style>
<div class='tutorial-wrap-inner'>
<div class="tutorial" use:links>
  <div
    class="close"
    on:click={e => {
      showWelcomeCard = false
    }}>
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.9 11.1C28.6 10.8 28.2 10.8 27.9 11.1L20 19L12.1 11.1C11.8 10.8 11.4 10.8 11.1 11.1C10.8 11.4 10.8 11.8 11.1 12.1L19 20L11.1 27.9C10.8 28.2 10.8 28.6 11.1 28.9C11.4 29.2 11.8 29.2 12.1 28.9L20 21L27.9 28.9C28.2 29.2 28.6 29.2 28.9 28.9C29.2 28.6 29.2 28.2 28.9 27.9L21 20L28.9 12.1C29.2 11.8 29.2 11.4 28.9 11.1Z" />
      </svg>
      
  </div>
  {#each card.slides as slide, index (slide._key)}
    {#if Array.isArray(get(slide, 'content.content', false)) && currentIndex === index}
      <div class="tutorial-slide" in:fade|local>
        <img height="300" src={urlFor(get(slide, 'topImage', "")).url()} alt='The Shape of a Practice'/>
        {@html renderBlockText(get(slide, 'content.content', []))}
      </div>
    {/if}
  {/each}
  {#if card.slides && card.slides.length > 0}
    <div class="navigation">
      <div
        class="nav-button back"
        class:disabled={currentIndex === 0}
        on:click={e => {
          currentIndex -= 1
        }}>
        Back
      </div>
      {#if currentIndex != card.slides.length - 1}
      <div
        class="nav-button next"
        class:disabled={currentIndex === card.slides.length - 1}
        on:click={e => {
          currentIndex += 1
        }}>
        Next
      </div>
      {:else}
      <div
        class="nav-button enter"
        on:click={e => {
          showWelcomeCard = false;
        }}>
        Enter
      </div>
      {/if}
    </div>
  {/if}
</div>
</div>