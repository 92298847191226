<script>
  // # # # # # # # # # # # # #
  //
  //  CLOCK
  //
  // # # # # # # # # # # # # #

  function berlinTime(){
    var d = new Date();
    var cet = new Intl.DateTimeFormat('de-DE', {
      timeZone: 'CET',
      hour: 'numeric',
      minute: 'numeric'
    }).format(d)
    time = cet;
    return cet;
  }

  var time = berlinTime();
  window.setInterval(berlinTime, 10000);
  berlinTime();

</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.clock-berlin {
  font-family: "hersheyduplex", "IBM Plex Mono", monospace;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.8);
  color: #f0f0f0;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  word-spacing: -0.3em; }
  .clock-berlin a {
    color: white;
    text-decoration: none; }
  @media (max-width: 800px) {
    .clock-berlin {
      top: 80px;
      z-index: 1; } }
</style>

<div class="clock-berlin">
    {time}&nbsp;CET
</div>