<script>
  // # # # # # # # # # # # # #
  //
  //  Single Case Study
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import ParticipantsList from "../lists/ParticipantsList.svelte"
  import EventList from "../lists/EventList.svelte"
  import MaterialItem from "./MaterialItem.svelte"
  import MetaData from "../MetaData.svelte"

  // *** PROPS
  export let caseStudy = {}
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.case-study-single .go-back {
  padding-top: 6px;
  padding-left: 12px;
  cursor: pointer; }
  .case-study-single .go-back svg path {
    fill: #999999;
    transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .case-study-single .go-back:hover svg path {
    fill: #555555; }

.case-study-single .main-header {
  padding: 18px;
  padding-top: 6px; }
  .case-study-single .main-header .color-icon {
    display: none;
    height: 20px;
    width: 20px;
    margin-right: 18px;
    float: left;
    background: #ed3656; }
    .case-study-single .main-header .color-icon.communication {
      background: #1cc227; }
    .case-study-single .main-header .color-icon.consensus-building {
      background: #a2dafd; }
    .case-study-single .main-header .color-icon.sensing {
      background: #fff538; }
    .case-study-single .main-header .color-icon.archiving {
      background: #f8c2e8; }
  .case-study-single .main-header .title {
    font-family: "hersheyduplex", "IBM Plex Sans", sans;
    font-size: 32px;
    font-weight: bold; }
  .case-study-single .main-header .participants {
    margin-top: 6px;
    color: #555555;
    font-family: "hersheyduplex", "IBM Plex Mono", monospace;
    font-size: 14px;
    display: inline-block; }

.case-study-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.case-study-single .material {
  padding: 18px; }

.case-study-single .text {
  padding: 18px 18px 0 18px;
  font-family: "hersheyduplex", "IBM Plex Sans", sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .case-study-single .text {
      padding-bottom: 18px; } }

.case-study-single .related-events {
  padding: 18px; }

.material-item {
  width: 100%;
  background: #f0f0f0;
  color: black;
  display: block;
  text-decoration: none;
  user-select: none;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer; }
  .material-item .row {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .material-item .row .title {
      font-family: "hersheyduplex", "IBM Plex Sans", sans;
      font-weight: bold;
      white-space: nowrap; }
    .material-item .row .elips {
      margin-left: 6px;
      margin-right: 6px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 4;
      color: #999999; }
    .material-item .row .format {
      white-space: nowrap;
      color: #999999; }
  .material-item:hover {
    background: #c5c5c5; }
  .material-item.header {
    padding-top: 0px;
    border-bottom: 1px dotted #c5c5c5;
    cursor: default; }
    .material-item.header .archive-link {
      color: #999999;
      text-decoration: underline; }
    .material-item.header:hover {
      background: unset; }
</style>

<!-- METADATA -->
<!-- <MetaData post={caseStudy} /> -->

<div class="case-study-single" in:fade use:links>
  <!-- HEADER -->

  {#if caseStudy.title}
    <!-- BACK LINK -->
    <div
      class="go-back"
      on:click={e => {
        window.history.back()
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"><path
          d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" /></svg>
    </div>
    <div class="main-header">
      <!-- COLOR CODE  -->
      <div class="color-icon {caseStudy.category}" />
      <!-- TITLE -->
      <div class="title">{caseStudy.title}</div>
      <!-- PARTICIPANTS -->
      {#if get(caseStudy, 'participants', false) && Array.isArray(caseStudy.participants)}
        <div class="participants">
          <ParticipantsList
            participants={caseStudy.participants}
            messaging={true} />
        </div>
      {/if}
    </div>
    <div class="divider" />
  {/if}

  <!-- TEXT -->
  {#if Array.isArray(get(caseStudy, 'content.content', false))}
    <div class="text">
      {@html renderBlockText(caseStudy.content.content)}
    </div>
    <div class="divider" />
  {/if}

  <!-- MATERIAL -->
  {#if Array.isArray(caseStudy.material)}
    <div class="material">
      <div class="material-item header">
        <div class="row">
          <div class="title">Material</div>
          <div class="format">Type</div>
        </div>
      </div>
      {#each caseStudy.material as item (item._key)}
        <MaterialItem {item} />
      {/each}
    </div>
    <div class="divider" />
  {/if}

  <!-- RELATED EVENTS -->
  {#if Array.isArray(get(caseStudy, 'connectedEvents', false))}
    <div class="related-events">
      <EventList events={caseStudy.connectedEvents} related={true} />
    </div>
  {/if}
</div>
