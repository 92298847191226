<script>
  // # # # # # # # # # # # # #
  //
  //  FULL EVENT LIST
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { fade } from "svelte/transition"
  import get from "lodash/get"
  import { renderBlockText } from "../sanity.js"

  // *** GLOBAL
  import { formattedDate } from "../global.js"

  // COMPONENTS
  import ParticipantsList from "./ParticipantsList.svelte"

  // *** STORES
  import { globalSettings } from "../stores.js"

  // *** PROPS
  export let events = []
  export let exhibitions = []

  // *** VARIABLES
  let containerWidth = "100%"

  const now = Date.now()
  const upcomingEvents = events.filter(
    e => Date.parse(e.endDate ? e.endDate : e.startDate) > now
  )
  const archivedEvents = events.filter(
    e => Date.parse(e.endDate ? e.endDate : e.startDate) < now
  )
</script>

<style lang="scss">@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheyduplex';
  src: url("/fonts/Hershey-Noailles-Futura-Duplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'hersheysimplex';
  src: url("/fonts/Hershey-Noailles-Futura-Simplex-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hersheydashed';
  src: url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.woff") format("woff"), url("/fonts/Hershey-Noailles-pitchoun-dashed-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.eventlist-container {
  height: 100%;
  color: black;
  font-size: 16px;
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  @media (max-width: 800px) {
    .eventlist-container {
      padding-top: 0; } }
  .eventlist-container .inner-container {
    height: calc(100% - 80px);
    overflow-y: auto;
    -ms-overflow-style: none; }
    .eventlist-container .inner-container::-webkit-scrollbar {
      display: none; }
    @media (max-width: 800px) {
      .eventlist-container .inner-container {
        height: 100%; } }
  .eventlist-container .description {
    padding: 12px; }
  .eventlist-container .event {
    padding: 12px;
    width: 100%;
    background: #f0f0f0;
    color: black;
    display: block;
    text-decoration: none;
    user-select: none;
    transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
    @media (max-width: 800px) {
      .eventlist-container .event {
        display: inline-flex;
        padding-top: 12px; } }
    .eventlist-container .event .inner {
      width: 100%; }
      .eventlist-container .event .inner .row {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .eventlist-container .event .inner .row .title {
          font-size: 20px;
          font-family: "hersheyduplex", "IBM Plex Sans", sans;
          font-weight: bold;
          max-width: 70%;
          margin-bottom: 3px; }
        .eventlist-container .event .inner .row .elips {
          margin-left: 6px;
          margin-right: 6px;
          width: 60%;
          white-space: nowrap;
          overflow: hidden;
          flex-shrink: 4;
          color: #555555; }
        .eventlist-container .event .inner .row .date {
          color: #999999;
          font-family: "hersheysimplex", sans-serif;
          font-weight: normal;
          font-size: 90%;
          white-space: nowrap;
          text-align: right; }
        .eventlist-container .event .inner .row .participants {
          font-family: "hersheysimplex", sans-serif;
          font-weight: normal;
          pointer-events: none;
          color: #555555;
          font-size: 14px; }
    .eventlist-container .event:hover {
      background: #c5c5c5; }
    .eventlist-container .event.footer {
      height: 48px;
      border-top: 1px solid #c5c5c5;
      padding-bottom: 12px; }
      .eventlist-container .event.footer:hover {
        background: unset; }
      @media (max-width: 800px) {
        .eventlist-container .event.footer {
          display: none; } }
    .eventlist-container .event.header {
      height: 45px;
      border-bottom: 1px solid #c5c5c5;
      padding-bottom: 12px;
      background-color: white; }
      .eventlist-container .event.header:hover {
        background: white !important; }
      .eventlist-container .event.header .archive-link {
        color: #999999;
        text-decoration: underline; }
      .eventlist-container .event.header:hover {
        background: unset; }

.exhibition {
  padding: 0px 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  background: #f0f0f0;
  color: black;
  display: block;
  text-decoration: none;
  user-select: none;
  overflow: hidden;
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
  @media (max-width: 800px) {
    .exhibition {
      display: inline-flex;
      padding-top: 12px; } }
  .exhibition .inner {
    width: 100%; }
    .exhibition .inner .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline; }
      .exhibition .inner .row .title {
        font-size: 20px;
        font-family: "hersheyduplex", "IBM Plex Sans", sans;
        font-weight: bold;
        max-width: 70%;
        margin-bottom: 3px; }
      .exhibition .inner .row .elips {
        margin-left: 6px;
        margin-right: 6px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 999999;
        color: #555555; }
      .exhibition .inner .row .date {
        font-family: "hersheysimplex", sans-serif;
        font-weight: normal;
        font-size: 90%;
        white-space: nowrap;
        color: #555555; }
      .exhibition .inner .row .participants {
        font-family: "hersheysimplex", sans-serif;
        font-weight: normal;
        pointer-events: none;
        color: #555555;
        font-size: 14px; }
    .exhibition .inner .elips {
      margin-left: 6px;
      margin-right: 6px;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 999999;
      color: #999999; }
    .exhibition .inner .date {
      font-size: 90%;
      color: #999999;
      white-space: nowrap;
      text-align: right; }
    .exhibition .inner .participants {
      pointer-events: none;
      color: #999999;
      font-size: 14px; }
  .exhibition:hover {
    background: #c5c5c5; }
</style>

<div class="eventlist-container" style={'width:' + containerWidth + ';'}>
  <!-- HEADER -->
  <div class="event header">
    <div class="inner">
      <div class="row">
        <div>Events</div>
      </div>
    </div>
  </div>

  <!-- TEXT -->
  {#if Array.isArray(get($globalSettings, 'eventOverview.content', false))}
    <div class="description">
      {@html renderBlockText($globalSettings.eventOverview.content)}
    </div>
  {/if}

  <!-- EVENTS -->
  <div class="inner-container">
    <!-- ARCHIVED EVENTS -->
    {#if archivedEvents.length}
      <div class="section">
        <!-- SUBHEADER -->
        <div class="event header">
          <div class="inner">
            <div class="row">
              <div>Archived Events</div>
            </div>
          </div>
        </div>
        {#each archivedEvents as event, index (event._id)}
          <a
            class="event"
            in:fade={{ delay: 100 * index }}
            href={'/events/' + get(event, 'slug.current', '')}>
            <div class="inner">
              <div class="row">
                <div class="title">{event.title}</div>
                <!-- <div class="elips">
                              .........................................................
                          </div> -->
                <div class="date">{formattedDate(event.startDate)}</div>
              </div>
              <div class="row">
                <div class="participants">
                  {#if get(event, 'moderators', false) && Array.isArray(event.moderators)}
                    <ParticipantsList
                      participants={event.moderators}
                      isModerators />
                  {/if}
                  {#if get(event, 'participants', false) && Array.isArray(event.participants)}
                    <ParticipantsList participants={event.participants} />
                  {/if}
                </div>
              </div>
            </div>
          </a>
        {/each}
      </div>
    {/if}

    <!-- UPCOMING EVENTS -->
    <div class="section">
      <!-- SUBHEADER -->
      <div class="event header">
        <div class="inner">
          <div class="row">
            <div>Upcoming Events</div>
          </div>
        </div>
      </div>
      {#each upcomingEvents as event, index (event._id)}
        <a
          class="event"
          in:fade={{ delay: 100 * index }}
          href={'/events/' + get(event, 'slug.current', '')}>
          <div class="inner">
            <div class="row">
              <div class="title">{event.title}</div>
              <!-- <div class="elips">
                            .........................................................
                        </div> -->
              <div class="date">{formattedDate(event.startDate)}</div>
            </div>
            <div class="row">
              <div class="participants">
                {#if get(event, 'moderators', false) && Array.isArray(event.moderators)}
                  <ParticipantsList
                    participants={event.moderators}
                    isModerators />
                {/if}
                {#if get(event, 'participants', false) && Array.isArray(event.participants)}
                  <ParticipantsList participants={event.participants} />
                {/if}
              </div>
            </div>
          </div>
        </a>
      {/each}
    </div>

    <!-- EXHIBITIONS -->
    <!-- <div class="section"> -->
    <!-- SUBHEADER -->
    <!-- <div class="event header">
        <div class="inner">
          <div class="row">
            <div>Ongoing</div>
          </div>
        </div>
      </div> -->
    <!-- {#each exhibitions.reverse() as exhibition, index (exhibition._id)}
        <a
          href={'/area/' + get(exhibition, 'area.slug.current', '')}
          class="exhibition">
          <div class="inner">
            <div class="row">
              <div class="title">{exhibition.title}</div>
              <div class="elips">
                .........................................................
              </div>
              <div class="date">{exhibition.period}</div>
            </div>
          </div>
        </a>
      {/each} -->
    <!-- </div> -->
  </div>
</div>
